import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MainLayout from 'app/components/layouts/MainLayout';
import NotFoundPage from 'app/components/NotFoundPage';
import ListPageRoute from './ListPageRoute';

const basePath = '/v2/list';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route exact path={`${basePath}/display/:userId/:listId`}>
          <ListPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
