import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from 'app/components/layouts/MainLayout';
import NotFoundPage from 'app/components/NotFoundPage';
import BrowsePageRoute from './BrowsePageRoute';

export default (
  <Route path="/v2">
    <MainLayout>
      <Switch>
        <Route exact path="/v2">
          <Redirect to="/v2/browse" />
        </Route>
        <Route exact path="/v2/browse">
          <BrowsePageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
