import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import CommentPageRoute from './CommentPageRoute';

const basePath = '/v2/comment';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route path={`${basePath}/:commentId`}>
          <CommentPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
