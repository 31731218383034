import React from 'react';
import NotFound from 'app/components/shared/NotFound';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';

export default function NotFoundPage() {
  return (
    <div className="container">
      <DocumentHeadContainer noIndex pageTitle="404" />
      <NotFound />
    </div>
  );
}
