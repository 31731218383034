import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate } from 'react-intl';
import moment from 'moment';

const ShortFormattedDate = ({ value }) => {
  if (value) {
    return (
      <FormattedDate value={moment.utc(value).toDate()} year="numeric" month="short" day="2-digit">
        {message => <span className="cp-short-formatted-date">{message}</span>}
      </FormattedDate>
    );
  }

  return null;
};

ShortFormattedDate.propTypes = {
  value: PropTypes.string
};

export default ShortFormattedDate;
