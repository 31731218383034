import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../styles/NotFound.scss';

class NotFound extends React.PureComponent {
  render() {
    return (
      <div className="cp-not-found">
        <h1>404</h1>
        <h2>
          <FormattedMessage id="errors.pageDoesntExist" />
        </h2>
        <p>
          <FormattedMessage id="errors.urlDoesntExist" />
        </p>
      </div>
    );
  }
}

export default NotFound;
