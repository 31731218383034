import { MATERIAL_TYPE_DIGITAL, MATERIAL_TYPE_PHYSICAL } from 'app/constants/BibConstants';
import { HOLD_STATUS_TYPES } from 'app/constants/HoldsConstants';

const TRANSACTION_BUTTON_TYPE = {
  NONE: 'NONE',
  CANCEL_HOLD: 'CANCEL_HOLD',
  DIGITAL_OPTIONS: 'DIGITAL_OPTIONS',
  REQUEST_ITEM: 'REQUEST_ITEM',
  REQUEST_TO_VIEW: 'REQUEST_TO_VIEW'
};

const isDigitalReadyHold = ({ bib, hold }) => {
  const isBibDigital = bib?.policy?.materialType === MATERIAL_TYPE_DIGITAL;
  const isHeld = Boolean(hold);
  const isReadyForPickup = hold?.status === HOLD_STATUS_TYPES.READY_FOR_PICKUP;

  return isBibDigital && isHeld && isReadyForPickup;
};

const isRequestToView = ({ bib, library }) => {
  const libraryHasRequestToView = Boolean(library?.hasRequestToView);
  const bibIsAvailableByRequest =
    bib?.availability?.localisedStatus === 'AVAILABLE_BY_REQUEST' ||
    bib?.availability?.localisedStatus === 'LIBRARY_USE_ONLY_AVAILABLE_BY_REQUEST';
  const bibIsPhysical = bib?.policy?.materialType === MATERIAL_TYPE_PHYSICAL;

  return libraryHasRequestToView && bibIsAvailableByRequest && bibIsPhysical;
};

const getItemTransactionButtonType = ({ bib, checkout, hold, library = null }) => {
  if (isRequestToView({ bib, library })) {
    return TRANSACTION_BUTTON_TYPE.REQUEST_TO_VIEW;
  }

  const isBibHoldable = bib?.policy?.holdable ?? false;
  if (!isBibHoldable) {
    return TRANSACTION_BUTTON_TYPE.NONE;
  }

  const isBibItemLevelHoldable = bib?.policy?.itemLevelHoldableItems ?? false;
  const isCheckedOut = Boolean(checkout);
  const isHeld = Boolean(hold);
  const isDigitalBib = bib?.policy?.materialType === MATERIAL_TYPE_DIGITAL;
  const isHoldItemLevel = Boolean(hold?.itemId);

  // Show the cancel hold button if the item has an existing hold that is not item level,
  // even if the bib is marked as having item level holdable items.
  //
  // This is necessary because if the ILS does not support predetermined item level holds
  // a bib with item level holdable items may or may not be item level holdable.
  const canItemLevelHold = isBibItemLevelHoldable && (!isHeld || isHoldItemLevel);

  if (isHeld && !isDigitalReadyHold({ bib, hold }) && !canItemLevelHold) {
    return TRANSACTION_BUTTON_TYPE.CANCEL_HOLD;
  }

  if (isDigitalBib && isCheckedOut && !isBibItemLevelHoldable) {
    return TRANSACTION_BUTTON_TYPE.DIGITAL_OPTIONS;
  }

  return TRANSACTION_BUTTON_TYPE.REQUEST_ITEM;
};

const getPropsForFormatInfo = ({ bib, isIncludeCallNumberOverride = null, library, searchScopeIsCurrent = false }) => {
  const LANGUAGE_CODES_TO_EXCLUDE = ['eng', 'und', 'zxx'];

  const isIncludeEdition =
    !library?.onlyShowEditionsForCDROMAndVideoGames || ['CDROM', 'VIDEO_GAME'].includes(bib?.briefInfo?.format);
  const isIncludeCallNumber =
    isIncludeCallNumberOverride ??
    (library?.showCallNumber &&
      (library?.showCallNumberForDigital || bib?.policy?.materialType === MATERIAL_TYPE_PHYSICAL) &&
      (!library?.hideCallNumberForHigherSearchScope || searchScopeIsCurrent));
  const format = bib?.briefInfo?.format;
  const isIncludeLanguageCode =
    Boolean(bib?.briefInfo?.primaryLanguage) && !LANGUAGE_CODES_TO_EXCLUDE.includes(bib?.briefInfo?.primaryLanguage);

  return {
    callNumber: isIncludeCallNumber ? bib?.briefInfo?.callNumber ?? '' : '',
    edition: isIncludeEdition ? bib?.briefInfo?.edition ?? '' : '',
    formatMessageId: format ? `FORMAT.${format.toLowerCase()}` : '',
    languageMessageId: isIncludeLanguageCode ? `LANGUAGE.${bib?.briefInfo?.primaryLanguage}` : '',
    publicationDate: bib?.briefInfo?.publicationDate ?? ''
  };
};

const getRecommendationProviderDisplayName = (providerKey, defaultValue) => {
  switch (providerKey?.toUpperCase()) {
    case 'BOOKISH':
      return 'Bookish';
    case 'LIBRARY_THING':
      return 'LibraryThing';
    case 'NOVELIST':
      return 'NoveList';
    default:
      return defaultValue ?? providerKey;
  }
};

const mapMetadataIdToLibraryId = metadataId => {
  const matches = /^S(\d*)C/i.exec(metadataId);
  if (!matches) {
    return null;
  }

  return matches[1];
};

const makeBibJacketCoverAltText = (bibTitle, authors) => {
  if (typeof authors !== 'undefined') {
    const authorResult = authors.size > 1 ? authors.join(', ') : authors.join();
    return `${bibTitle}${authorResult ? ' - ' : ''}${authorResult}`;
  }
  return bibTitle;
};

export {
  getItemTransactionButtonType,
  getPropsForFormatInfo,
  getRecommendationProviderDisplayName,
  isDigitalReadyHold,
  isRequestToView,
  mapMetadataIdToLibraryId,
  makeBibJacketCoverAltText,
  TRANSACTION_BUTTON_TYPE
};
