import createReduxConstants from 'app/helpers/redux/createReduxConstants';

// TODO: build-out enum of supported localised statuses
const AVAILABILITY_LOCALISED_STATUS = {
  AVAILABLE_IN_BRANCH: 'AVAILABLE_IN_BRANCH',
  AVAILABLE_NOT_HOLDABLE: 'AVAILABLE_NOT_HOLDABLE',
  DELETED: 'DELETED',
  DIGITAL_ALWAYS_AVAILABLE: 'DIGITAL_ALWAYS_AVAILABLE',
  ON_ORDER: 'ON_ORDER',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN: 'UNKNOWN'
};

// TODO: build-out enum
const AVAILABILITY_LOCATION_TYPE = {
  LOCAL: 'LOCAL',
  NOT_APPLICABLE: 'NA',
  OTHER: 'OTHER',
  PREFERRED: 'PREFERRED'
};

const AVAILABILITY_ROLLUP_STATUS = {
  AVAILABLE: 'AVAILABLE',
  ON_ORDER: 'ON_ORDER',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN: 'UNKNOWN'
};

// TODO: build-out the enum of supported bib format IDs
const BIB_FORMAT_ID = {
  AUDIOBOOK: 'AB',
  BOARD_BOOK: 'BOARD_BK',
  BOOK: 'BK',
  DVD: 'DVD',
  EBOOK: 'EBOOK',
  EASY_READER: 'EASY_READER',
  GRAPHIC_NOVEL: 'GRAPHIC_NOVEL',
  LARGE_PRINT: 'LP',
  MICROFORM: 'MF',
  PAPERBACK: 'PAPERBACK',
  PRINTED_MUSIC: 'MN',
  STREAMING_MUSIC: 'MUSIC_ONLINE',
  UNKNOWN: 'UK',
  WEBSITE: 'WEBSITE'
};

const BIB_IMAGE_INFO_TYPE = {
  LOCAL: 'LOCAL',
  NONLOCAL: 'NONLOCAL'
};

const ITEMS_PER_PAGE_LIMIT = 20;

const BIB_TITLE_TYPE = {
  MANIFESTATION: 'MANIFESTATION',
  VARIANT: 'VARIANT'
};

const LIST_ITEM_TYPE = {
  BIB: 'bib',
  WEB: 'url'
};

// In the localizations en-US file
// there are a number of types that are no longer supported
// but may be assigned to older lists.
const LIST_TYPE = {
  GENERAL_RECOMMENDATIONS: 'other',
  GENRE_GUIDE: 'genre_guide',
  HEALTHCARE_GUIDE: 'health_guide',
  IF_YOU_LIKED: 'read_a_like',
  'K-12_STUDY_GUIDE': 'k12_study_guide',
  PERSONAL_RECOMMENDATION: 'personal_recommendation',
  TOP_PICKS: 'top_10',
  TOPIC_GUIDE: 'topic_guide'
};

const REDUX_CONSTANTS = createReduxConstants('LIST', {
  GET_BIB_ITEM_AVAILABILITIES_REQUEST: null,
  GET_BIB_ITEM_AVAILABILITIES_SUCCESS: null,
  GET_BIB_ITEM_AVAILABILITIES_FAILURE: null,

  GET_LIST_BY_ID_REQUEST: null,
  GET_LIST_BY_ID_SUCCESS: null,
  GET_LIST_BY_ID_FAILURE: null,

  GET_LIST_ITEMS_REQUEST: null,
  GET_LIST_ITEMS_SUCCESS: null,
  GET_LIST_ITEMS_FAILURE: null
});

export {
  AVAILABILITY_LOCALISED_STATUS,
  AVAILABILITY_LOCATION_TYPE,
  AVAILABILITY_ROLLUP_STATUS,
  BIB_FORMAT_ID,
  BIB_IMAGE_INFO_TYPE,
  BIB_TITLE_TYPE,
  ITEMS_PER_PAGE_LIMIT,
  LIST_ITEM_TYPE,
  LIST_TYPE,
  REDUX_CONSTANTS
};
