import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import LocationsPageRoute from './LocationsPageRoute';

const basePath = '/v2/locations';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route exact path={basePath}>
          <LocationsPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
