import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainLayout from 'app/components/layouts/MainLayout';
import NotFoundPage from 'app/components/NotFoundPage';

import availabilityRoutes from './availability';
import bibsRoutes from './bibs';
import browseRoutes from './browse';
import searchRoutes from './search';
import usersRoutes from './users';
import holdsRoutes from './borrowing/holds';
import infoRoutes from './info';
import listRoutes from './list';
import checkedoutRoutes from './borrowing/checkedout';
import accountMessagesRoutes from './borrowing/accountMessages';
import interlibraryLoansRoutes from './borrowing/interlibraryLoans';
import recentlyReturnedRoutes from './borrowing/recentlyReturned';
import finesRoutes, { finesSubroutes } from './borrowing/fines';
import commentsRoutes from './ugc/comments';
import quotationsRoutes from './ugc/quotations';
import summariesRoutes from './ugc/summaries';
import videosRoutes from './ugc/videos';
import eventsRoutes from './events/search';
import locationsRoutes from './locations';
import myEventsRoutes from './events/account';
import printRoutes from './print';
import settingsRoutes from './settings';
import widgetsRoutes from './widgets';

export default (
  <Switch>
    {printRoutes}
    {availabilityRoutes}
    {accountMessagesRoutes}
    {bibsRoutes}
    {checkedoutRoutes}
    {commentsRoutes}
    {myEventsRoutes}
    {eventsRoutes}
    {locationsRoutes}
    {finesSubroutes}
    {finesRoutes}
    {holdsRoutes}
    {listRoutes}
    {infoRoutes}
    {interlibraryLoansRoutes}
    {quotationsRoutes}
    {recentlyReturnedRoutes}
    {searchRoutes}
    {settingsRoutes}
    {summariesRoutes}
    {usersRoutes}
    {videosRoutes}
    {widgetsRoutes}
    {browseRoutes}
    <Route>
      <MainLayout>
        <NotFoundPage />
      </MainLayout>
    </Route>
  </Switch>
);
