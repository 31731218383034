import { BROCHURE_LIMIT } from 'app/constants/EventsConstants';
import EventsApi from '../api/EventsApi';
import EventsConstants from '../constants/EventsConstants';
import composeGlobalSuccessMessaging from '../helpers/messaging/composeGlobalSuccessMessaging';

function searchEvents(query = {}) {
  return {
    query,
    types: [
      EventsConstants.SEARCH_EVENTS_REQUEST,
      EventsConstants.SEARCH_EVENTS_SUCCESS,
      EventsConstants.SEARCH_EVENTS_FAILURE
    ],
    apiRequest: (state, client) => EventsApi.searchEvents({ ...query, limit: 20 }, client)
  };
}

function getCalloutFeaturedEvent() {
  return {
    types: [
      EventsConstants.FETCH_CALLOUT_FEATURED_EVENT_REQUEST,
      EventsConstants.FETCH_CALLOUT_FEATURED_EVENT_SUCCESS,
      EventsConstants.FETCH_CALLOUT_FEATURED_EVENT_FAILURE
    ],
    apiRequest: (state, client) =>
      EventsApi.getEvents(
        {
          featured: true,
          cancelled: false,
          full: false,
          limit: 1,
          sort: 'definition.start asc'
        },
        client
      )
  };
}

function getMyEvents(query = {}) {
  return {
    query,
    types: [EventsConstants.MY_EVENTS_REQUEST, EventsConstants.MY_EVENTS_SUCCESS, EventsConstants.MY_EVENTS_FAILURE],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) => {
      return EventsApi.getMyEvents({ ...query, limit: 20 }, client);
    }
  };
}

function cancelRegistrationSuccess(removeFromWaitlist) {
  if (removeFromWaitlist) {
    return {
      messaging: composeGlobalSuccessMessaging('registration.remove_waitlist.success_toast_message')
    };
  }
  return {
    messaging: composeGlobalSuccessMessaging('registration.cancel.success_toast_message')
  };
}

function cancelRegistration({ registrationId, removeFromWaitlist, postSuccess }) {
  return {
    registrationId,
    removeFromWaitlist,
    postSuccess,
    types: [
      EventsConstants.MY_EVENTS_CANCEL_REQUEST,
      EventsConstants.MY_EVENTS_CANCEL_SUCCESS,
      EventsConstants.MY_EVENTS_CANCEL_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) => EventsApi.cancelRegistration({ registrationId }, client),
    apiSuccess: () => cancelRegistrationSuccess(removeFromWaitlist)
  };
}

function removeFromMyEvents({ registrationId, postSuccess }) {
  return {
    registrationId,
    postSuccess,
    types: [
      EventsConstants.MY_EVENTS_CANCEL_REQUEST,
      EventsConstants.MY_EVENTS_CANCEL_SUCCESS,
      EventsConstants.MY_EVENTS_CANCEL_FAILURE
    ],
    middlewareData: {
      authRequired: true
    },
    apiRequest: (state, client) => EventsApi.updateRegistration({ registrationId, remove: true }, client),
    apiSuccess: () => ({
      messaging: composeGlobalSuccessMessaging('registration.remove_from_my_events.success_toast_message')
    })
  };
}

function fetchEventsForBrochure(query = {}) {
  return {
    query,
    types: [
      EventsConstants.BROCHURE_EVENTS_REQUEST,
      EventsConstants.BROCHURE_EVENTS_SUCCESS,
      EventsConstants.BROCHURE_EVENTS_FAILURE
    ],
    apiRequest: (state, client) => EventsApi.fetchEventsForBrochure({ ...query, limit: BROCHURE_LIMIT }, client)
  };
}

export default {
  searchEvents,
  getCalloutFeaturedEvent,
  getMyEvents,
  cancelRegistration,
  removeFromMyEvents,
  fetchEventsForBrochure
};
