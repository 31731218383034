import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import QuotationPageRoute from './QuotationPageRoute';

const basePath = '/v2/quotation';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route path={`${basePath}/:quotationId`}>
          <QuotationPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
