import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from 'app/components/layouts/MainLayout';
import NotFoundPage from 'app/components/NotFoundPage';

import appsRoutes from './apps';

const basePath = '/v2/info';

export default (
  <Route path={basePath}>
    <Switch>
      <Redirect exact from={basePath} to={`${basePath}/apps`} />
      {appsRoutes}
      <Route>
        <MainLayout>
          <NotFoundPage />
        </MainLayout>
      </Route>
    </Switch>
  </Route>
);
