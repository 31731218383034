import PropTypes from 'prop-types';
import React from 'react';
import { bindAll } from '@bibliocommons/utils-react';
import cn from 'classnames';
import Swipeable from '@bibliocommons/base-gestures-swipeable';
import manageFocus from '@bibliocommons/base-accessibility-manage-focus';
import { childrenShape } from '@bibliocommons/bc-prop-types';

class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    bindAll(this);
    this._sidebar = React.createRef();
  }

  componentDidMount() {
    this._sidebar.current.addEventListener('transitionend', this.toggleFocus);
  }

  componentWillUnmount() {
    this._sidebar.current.removeEventListener('transitionend', this.toggleFocus);
  }

  toggleFocus() {
    if (this.props.open) {
      this.props.captureFocus(this._sidebar.current);
    } else {
      this.props.restoreFocus();
    }
  }

  render() {
    const sidebarClassNames = cn(
      this.props.sidebarClass,
      'col-10 col-xs-10 col-sm-8 col-md-7 col-lg-3 cp-layout-sidebar'
    );

    return (
      <Swipeable className={sidebarClassNames} onSwipedLeft={this.props.drawerActions.toggle}>
        {/* eslint-disable */}
        <div className="sidebar-backdrop" onClick={this.props.drawerActions.toggle} />
        {/* eslint-enable */}
        <section className="sidebar-overlay" ref={this._sidebar}>
          {this.props.children}
        </section>
      </Swipeable>
    );
  }
}

Sidebar.propTypes = {
  drawerActions: PropTypes.shape({
    toggle: PropTypes.func.isRequired
  }),
  sidebarClass: PropTypes.string,
  children: childrenShape,
  restoreFocus: PropTypes.func,
  open: PropTypes.bool,
  captureFocus: PropTypes.func
};

Sidebar.defaultProps = {
  sidebarClass: ''
};

export default manageFocus(Sidebar);
