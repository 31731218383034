import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import EventsSearchPageRoute from './EventsSearchPageRoute';

const basePath = '/v2/events';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route exact path={basePath}>
          <EventsSearchPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
