import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import SearchPageRoute from './SearchPageRoute';

const basePath = '/v2/search';

export default (
  <Route path={basePath}>
    <MainLayout deprecated>
      <Switch>
        <Route exact path={basePath}>
          <SearchPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
