import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from 'app/components/layouts/MainLayout';
import NotFoundPage from 'app/components/NotFoundPage';
import AuthenticatedRoute from 'app/routes/AuthenticatedRoute';
import AccountRequiredRoute from 'app/routes/AccountRequiredRoute';
import UnregisterPageRoute from 'app/routes/settings/account/UnregisterPageRoute';

const basePath = '/v2/settings';

export default (
  <Route path={basePath}>
    <AuthenticatedRoute>
      <AccountRequiredRoute>
        <MainLayout>
          <Switch>
            <Redirect exact from={basePath} to={`${basePath}/account`} />
            <Redirect exact from={`${basePath}/account`} to={`${basePath}/account/unregister`} />
            <Route path={`${basePath}/account/unregister`}>
              <UnregisterPageRoute />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </MainLayout>
      </AccountRequiredRoute>
    </AuthenticatedRoute>
  </Route>
);
