import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import UsersPageRoute from './UsersPageRoute';
import ShelfPageRoute from './ShelfPageRoute';

const basePath = '/v2/users/:userId';

export default (
  <Route path={basePath}>
    <MainLayout deprecated>
      <UsersPageRoute>
        <Switch>
          <Route path={`${basePath}/shelves/:shelfName`}>
            <ShelfPageRoute />
          </Route>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </UsersPageRoute>
    </MainLayout>
  </Route>
);
