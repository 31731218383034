import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFoundPage from 'app/components/NotFoundPage';

import MainLayout from 'app/components/layouts/MainLayout';
import BorrowingLayout from 'app/components/borrowing/shared/BorrowingLayout';
import AuthenticatedRoute from 'app/routes/AuthenticatedRoute';
import AccountRequiredRoute from 'app/routes/AccountRequiredRoute';
import FinesPayPageRoute from './pay/FinesPayPageRoute';
import FinesPageRoute from './FinesPageRoute';

const basePath = '/v2/fines';

export const finesSubroutes = (
  <Route path={`${basePath}/:slug`}>
    <AuthenticatedRoute>
      <AccountRequiredRoute>
        <MainLayout>
          <Switch>
            <Route path={`${basePath}/pay`}>
              <FinesPayPageRoute />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </MainLayout>
      </AccountRequiredRoute>
    </AuthenticatedRoute>
  </Route>
);

export default (
  <Route path={basePath}>
    <AuthenticatedRoute>
      <AccountRequiredRoute>
        <MainLayout deprecated>
          <BorrowingLayout>
            <FinesPageRoute />
          </BorrowingLayout>
        </MainLayout>
      </AccountRequiredRoute>
    </AuthenticatedRoute>
  </Route>
);
