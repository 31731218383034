import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import MainLayout from 'app/components/layouts/MainLayout';
import BibPageRoute from './BibPageRoute';
import AllCommentsPageRoute from './AllCommentsPageRoute';
import AllListsPageRoute from './AllListsPageRoute';
import AllQuotationsPageRoute from './AllQuotationsPageRoute';
import AllReviewsPageRoute from './AllReviewsPageRoute';
import AllRecommendationsPageRoute from './AllRecommendationsPageRoute';
import AllSummariesPageRoute from './AllSummariesPageRoute';
import AllVideosPageRoute from './AllVideosPageRoute';
import MarcPageRoute from './MarcPageRoute';
import AllContentNoticesPageRoute from './AllContentNoticesPageRoute';

const basePath = '/v2/record/:metadataId';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Route exact path={basePath}>
          <BibPageRoute />
        </Route>
        <Route path={`${basePath}/comments`}>
          <AllCommentsPageRoute />
        </Route>
        <Route path={`${basePath}/quotations`}>
          <AllQuotationsPageRoute />
        </Route>
        <Route path={`${basePath}/summaries`}>
          <AllSummariesPageRoute />
        </Route>
        <Route path={`${basePath}/reviews`}>
          <AllReviewsPageRoute />
        </Route>
        <Route path={`${basePath}/recommendations`}>
          <AllRecommendationsPageRoute />
        </Route>
        <Route path={`${basePath}/lists`}>
          <AllListsPageRoute />
        </Route>
        <Route path={`${basePath}/videos`}>
          <AllVideosPageRoute />
        </Route>
        <Route path={`${basePath}/originalrecord`}>
          <MarcPageRoute />
        </Route>
        <Route path={`${basePath}/contentnotices`}>
          <AllContentNoticesPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
