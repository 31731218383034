import PropTypes from 'prop-types';
import React from 'react';
import { childrenShape } from '@bibliocommons/bc-prop-types';
import '../styles/Layout.scss';

const DRAWER_OPEN_CLASS = 'drawer-open';

class Layout extends React.PureComponent {
  componentDidMount() {
    Layout.toggleBodyScrolling(this.props.open);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      Layout.toggleBodyScrolling(this.props.open);
    }
  }

  componentWillUnmount() {
    Layout.toggleBodyScrolling(false);
  }

  static toggleBodyScrolling(open) {
    const method = open ? 'add' : 'remove';
    document.body.classList[method](DRAWER_OPEN_CLASS);
  }

  getDrawerClasses(open) {
    const activeClass = open ? 'active' : '';
    return `cp-layout ${this.props.containerClass} ${activeClass} drawer-layout-left row`;
  }

  render() {
    return (
      <div className={this.getDrawerClasses(this.props.open)}>
        {React.Children.map(this.props.children, child => React.cloneElement(child, { open: this.props.open }))}
      </div>
    );
  }
}

Layout.propTypes = {
  open: PropTypes.bool,
  containerClass: PropTypes.string,
  children: childrenShape
};

export default Layout;
