import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from 'app/components/NotFoundPage';
import PrintLayout from 'app/components/shared/PrintLayout';
import AuthenticatedRoute from 'app/routes/AuthenticatedRoute';
import AccountRequiredRoute from 'app/routes/AccountRequiredRoute';
import UsersPageRoute from '../users/UsersPageRoute';
import CheckedOutPrintListRoute from './CheckedOutPrintListRoute';
import HoldsPrintListRoute from './HoldsPrintListRoute';
import InterlibraryLoansPrintListRoute from './InterlibraryLoansPrintListRoute';
import RecentlyReturnedPrintListRoute from './RecentlyReturnedPrintListRoute';
import SearchPrintListRoute from './SearchPrintListRoute';
import ShelfPrintListRoute from './ShelfPrintListRoute';

const basePath = '/v2/print';

export default (
  <Route path="/v2/print">
    <PrintLayout>
      <Switch>
        <Route path={`${basePath}/checkedout/:status?`}>
          <AuthenticatedRoute>
            <AccountRequiredRoute>
              <CheckedOutPrintListRoute />
            </AccountRequiredRoute>
          </AuthenticatedRoute>
        </Route>
        <Route path={`${basePath}/holds/:status?`}>
          <AuthenticatedRoute>
            <AccountRequiredRoute>
              <HoldsPrintListRoute />
            </AccountRequiredRoute>
          </AuthenticatedRoute>
        </Route>
        <Route path={`${basePath}/interlibraryloans/:status?`}>
          <AuthenticatedRoute>
            <AccountRequiredRoute>
              <InterlibraryLoansPrintListRoute />
            </AccountRequiredRoute>
          </AuthenticatedRoute>
        </Route>
        <Route path={`${basePath}/recentlyreturned`}>
          <AuthenticatedRoute>
            <AccountRequiredRoute>
              <RecentlyReturnedPrintListRoute />
            </AccountRequiredRoute>
          </AuthenticatedRoute>
        </Route>
        <Route path={`${basePath}/search`}>
          <SearchPrintListRoute />
        </Route>
        <Route path={`${basePath}/users/:userId/shelves/:shelfName`}>
          <UsersPageRoute>
            <ShelfPrintListRoute />
          </UsersPageRoute>
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </PrintLayout>
  </Route>
);
