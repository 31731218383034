import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const actionTypes = createReduxConstants('ANALYTICS_GA4', {
  SET_SDK_CONFIG: null,
  SET_SEARCH_ORIGIN: null,
  TRACK_CATALOG_EXPLORE: null,
  TRACK_CATALOG_SEARCH: null,
  TRACK_CHECKOUT_CONSUME_ACCESS: null,
  TRACK_CHECKOUT_CONSUME_DOWNLOAD: null,
  TRACK_CONTENT_EXPLORE: null,
  TRACK_CONTENT_CLICK: null,
  TRACK_CONTENT_COLLECTION_MOVE: null,
  TRACK_CONTENT_IMPRESSION: null,
  TRACK_EVENT_SEARCH: null,
  TRACK_HOLDINGS_CONSUME_ACCESS: null,
  TRACK_HOLDINGS_EXPLORE_EDITION: null,
  TRACK_HOLDINGS_EXPLORE_FORMAT: null,
  TRACK_HOLDINGS_VIEW: null,
  TRACK_NAVIGATE: null,
  TRACK_PAGE_PRINT: null,
  TRACK_PAGE_VIEW: null,
  TRACK_REQUEST_EXTERNAL_PLACE: null,
  TRACK_SDK_CHECKOUT_CONSUME_ACCESS: null,
  TRACK_SDK_CHECKOUT_PLACE: null,
  TRACK_SDK_HOLD_PLACE: null,
  TRACK_SDK_HOLDINGS_CONSUME_ACCESS: null,
  TRACK_SDK_HOLDINGS_VIEW: null,
  TRACK_SDK_LOG_IN: null,
  TRACK_SDK_USER_ACCOUNT_REGISTER: null,
  TRACK_SDK_USER_CONTENT_ADD: null,
  TRACK_SDK_USER_CONTENT_MODIFY: null,
  TRACK_SDK_USER_ENGAGE_FOLLOW: null
});

export const eventNames = {
  CATALOG_EXPLORE: 'bc_catalog_explore',
  CATALOG_SEARCH: 'bc_catalog_search',
  CHECKOUT_CONSUME_ACCESS: 'bc_checkout_consume_access',
  CHECKOUT_CONSUME_DOWNLOAD: 'bc_checkout_consume_download',
  CHECKOUT_PLACE: 'bc_checkout_place',
  CHECKOUT_RENEW: 'bc_checkout_renew',
  CONTENT_CLICK: 'bc_content_click',
  CONTENT_COLLECTION_ADD: 'bc_content_collection_add',
  CONTENT_COLLECTION_MOVE: 'bc_content_collection_move',
  CONTENT_EXPLORE: 'bc_content_explore',
  CONTENT_IMPRESSION: 'bc_content_impression',
  EVENT_SEARCH: 'bc_event_search',
  HOLD_PLACE: 'bc_hold_place',
  HOLDINGS_CONSUME_ACCESS: 'bc_holdings_consume_access',
  HOLDINGS_EXPLORE_EDITION: 'bc_holdings_explore_edition',
  HOLDINGS_EXPLORE_FORMAT: 'bc_holdings_explore_format',
  HOLDINGS_VIEW: 'bc_holdings_view',
  NAVIGATE: 'bc_navigate',
  PAGE_PRINT: 'bc_page_print',
  PAGE_VIEW: 'bc_page_view',
  REQUEST_EXTERNAL_PLACE: 'bc_request_external_place',
  SERVICE_FEEDBACK: 'bc_service_feedback',
  USER_ACCOUNT_REGISTER: 'bc_user_account_register',
  USER_CONTENT_ADD: 'bc_user_content_add',
  USER_CONTENT_MODIFY: 'bc_user_content_modify',
  USER_ENGAGE_FOLLOW: 'bc_user_engage_follow',
  USER_LOG_IN: 'bc_user_log_in'
};

export default {
  actionTypes,
  eventNames
};
