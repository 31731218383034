import { actionTypes } from '../constants/AnalyticsGa4Constants';

const setSdkConfig = payload => {
  return {
    type: actionTypes.SET_SDK_CONFIG,
    payload
  };
};

const setSearchOrigin = ({ searchOrigin }) => {
  return {
    type: actionTypes.SET_SEARCH_ORIGIN,
    payload: { searchOrigin }
  };
};

const trackCatalogExplore = ({ bibId, metadata, search = null, ui }) => {
  return {
    type: actionTypes.TRACK_CATALOG_EXPLORE,
    payload: {
      bibId,
      metadata,
      search,
      ui
    }
  };
};

const trackCatalogSearch = ({ search, ui }) => {
  return {
    type: actionTypes.TRACK_CATALOG_SEARCH,
    payload: {
      search,
      ui
    }
  };
};

const trackCheckoutConsumeAccess = bibId => {
  return {
    type: actionTypes.TRACK_CHECKOUT_CONSUME_ACCESS,
    payload: { bibId }
  };
};

const trackCheckoutConsumeDownload = bibId => {
  return {
    type: actionTypes.TRACK_CHECKOUT_CONSUME_DOWNLOAD,
    payload: { bibId }
  };
};

const trackContentClick = ({
  bib = null,
  bibId = null,
  cardId = null,
  event = null,
  list = null,
  listId = null,
  metadata = null,
  properties = null,
  search = null,
  ui = null
}) => {
  return {
    type: actionTypes.TRACK_CONTENT_CLICK,
    payload: {
      bib,
      bibId,
      cardId,
      event,
      list,
      listId,
      metadata,
      properties,
      search,
      ui
    }
  };
};

const trackContentCollectionMove = ({ bibId, properties }) => {
  return {
    type: actionTypes.TRACK_CONTENT_COLLECTION_MOVE,
    payload: {
      bibId,
      properties
    }
  };
};

const trackContentExplore = ({
  bibId = null,
  cardId = null,
  event = null,
  list = null,
  location = null,
  metadata = null,
  properties = null,
  search = null,
  ui = null
}) => {
  return {
    type: actionTypes.TRACK_CONTENT_EXPLORE,
    payload: {
      bibId,
      cardId,
      event,
      list,
      location,
      metadata,
      properties,
      search,
      ui
    }
  };
};

const trackContentImpression = ({ cardId, properties, search = null, ui }) => {
  return {
    type: actionTypes.TRACK_CONTENT_IMPRESSION,
    payload: {
      cardId,
      properties,
      search,
      ui
    }
  };
};

const trackEventSearch = ({ searchQuery }) => {
  return {
    type: actionTypes.TRACK_EVENT_SEARCH,
    payload: {
      searchQuery
    }
  };
};

const trackHoldingsConsumeAccess = ({ bibId, metadata, search = null, ui }) => {
  return {
    type: actionTypes.TRACK_HOLDINGS_CONSUME_ACCESS,
    payload: {
      bibId,
      metadata,
      search,
      ui
    }
  };
};

const trackHoldingsExploreEdition = ({ bibId, ui }) => {
  return {
    type: actionTypes.TRACK_HOLDINGS_EXPLORE_EDITION,
    payload: { bibId, ui }
  };
};

const trackHoldingsExploreFormat = ({ bibId, ui }) => {
  return {
    type: actionTypes.TRACK_HOLDINGS_EXPLORE_FORMAT,
    payload: { bibId, ui }
  };
};

const trackHoldingsView = ({ bibId, search = null, ui }) => {
  return {
    type: actionTypes.TRACK_HOLDINGS_VIEW,
    payload: {
      bibId,
      search,
      ui
    }
  };
};

const trackNavigate = ({ metadata, ui }) => {
  return {
    type: actionTypes.TRACK_NAVIGATE,
    payload: {
      metadata,
      ui
    }
  };
};

const trackRequestExternalPlace = ({ bibId, metadata, ui }) => {
  return {
    type: actionTypes.TRACK_REQUEST_EXTERNAL_PLACE,
    payload: { bibId, metadata, ui }
  };
};

const trackPagePrint = () => {
  return {
    type: actionTypes.TRACK_PAGE_PRINT
  };
};

const trackPageView = ({ bibId, list }) => {
  return {
    type: actionTypes.TRACK_PAGE_VIEW,
    payload: {
      bibId,
      list
    }
  };
};

const trackSdkCheckoutConsumeAccess = bib => {
  return {
    type: actionTypes.TRACK_SDK_CHECKOUT_CONSUME_ACCESS,
    payload: {
      bib
    }
  };
};

const trackSdkCheckoutPlace = bib => {
  return {
    type: actionTypes.TRACK_SDK_CHECKOUT_PLACE,
    payload: {
      bib
    }
  };
};

const trackSdkHoldingsConsumeAccess = ({ bib, metadata, ui }) => {
  return {
    type: actionTypes.TRACK_SDK_HOLDINGS_CONSUME_ACCESS,
    payload: {
      bib,
      metadata,
      ui
    }
  };
};

const trackSdkHoldingsView = bib => {
  return {
    type: actionTypes.TRACK_SDK_HOLDINGS_VIEW,
    payload: {
      bib
    }
  };
};

const trackSdkHoldPlace = (bib, properties) => {
  return {
    type: actionTypes.TRACK_SDK_HOLD_PLACE,
    payload: {
      bib,
      properties
    }
  };
};

const trackSdkLogin = user => {
  return {
    type: actionTypes.TRACK_SDK_LOG_IN,
    payload: {
      user
    }
  };
};

const trackSdkUserAccountRegister = user => {
  return {
    type: actionTypes.TRACK_SDK_USER_ACCOUNT_REGISTER,
    payload: {
      user
    }
  };
};

const trackSdkUserContentAdd = ({ list }) => {
  return {
    type: actionTypes.TRACK_SDK_USER_CONTENT_ADD,
    payload: {
      list
    }
  };
};

const trackSdkUserContentModify = ({ list }) => {
  return {
    type: actionTypes.TRACK_SDK_USER_CONTENT_MODIFY,
    payload: {
      list
    }
  };
};

const trackSdkUserEngageFollow = () => {
  return {
    type: actionTypes.TRACK_SDK_USER_ENGAGE_FOLLOW
  };
};

export {
  setSdkConfig,
  setSearchOrigin,
  trackCatalogExplore,
  trackCatalogSearch,
  trackCheckoutConsumeAccess,
  trackCheckoutConsumeDownload,
  trackContentCollectionMove,
  trackContentClick,
  trackContentExplore,
  trackContentImpression,
  trackEventSearch,
  trackHoldingsConsumeAccess,
  trackHoldingsExploreEdition,
  trackHoldingsExploreFormat,
  trackHoldingsView,
  trackNavigate,
  trackPagePrint,
  trackPageView,
  trackRequestExternalPlace,
  trackSdkCheckoutConsumeAccess,
  trackSdkCheckoutPlace,
  trackSdkHoldingsConsumeAccess,
  trackSdkHoldingsView,
  trackSdkHoldPlace,
  trackSdkLogin,
  trackSdkUserAccountRegister,
  trackSdkUserContentAdd,
  trackSdkUserContentModify,
  trackSdkUserEngageFollow
};
