import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from 'app/components/layouts/MainLayout';
import NotFoundPage from 'app/components/NotFoundPage';
import UnregisterPageRoute from './UnregisterPageRoute';

const basePath = '/v2/info/apps';

export default (
  <Route path={basePath}>
    <MainLayout>
      <Switch>
        <Redirect exact from={basePath} to={`${basePath}/unregister`} />
        <Route path={`${basePath}/unregister`}>
          <UnregisterPageRoute />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </MainLayout>
  </Route>
);
