/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { siteShape, accountShape, routerShape } from '@bibliocommons/bc-prop-types';
import * as Drawer from 'app/components/shared/DrawerLayout';
import { TextButton } from '@bibliocommons/deprecated-base-buttons';
import { Card as CardIcon } from '@bibliocommons/deprecated-base-icons';
import { ChevronLeft } from '@bibliocommons/base-icons';
import { withRouter } from '@bibliocommons/utils-routing';

import CheckedOutStatus from '../../checkouts/CheckedOutStatus';
import HoldsStatus from '../../holds/HoldsStatus';
import FinesStatus from '../../fines/FinesStatus';
import RecentlyReturnedStatus from '../../recentlyreturned/RecentlyReturnedStatus';
import AccountMessagesStatus from '../../accountmessages/AccountMessagesStatus';
import SingleClickHoldsPanelContainer from '../SingleClickHoldsPanel/SingleClickHoldsPanelContainer';
import AccountExpiryWarning from '../AccountExpiryWarning';
import LinkableLibrariesPanel from '../LinkableLibrariesPanel';
import InterlibraryLoansStatus from '../../interlibraryLoans/InterlibraryLoansStatus';

import './MyBorrowingSidebar.scss';

export class MyBorrowingSidebar extends React.PureComponent {
  isActive(section) {
    return this.props.activeSection === section;
  }

  showLinkableLibraries() {
    const unlinkedLibraries = this.props.linkableLibraries.filter(
      library => !this.props.accountsByLibraryId.has(library.get('libraryId'))
    );

    return unlinkedLibraries.size > 0;
  }

  renderSingleClickHoldsWidget() {
    if (this.props.activeSection === 'holds') {
      return <SingleClickHoldsPanelContainer />;
    }
  }

  renderTitle() {
    const { libraryName } = this.props;
    return (
      <h3>
        <div className="my-borrowing-at">
          <CardIcon />
          <FormattedMessage id="section_title_my_borrowing" />
        </div>
        <div className="lib-at">
          <FormattedMessage id="at_library" values={{ library: <strong>{libraryName}</strong> }} />
        </div>
      </h3>
    );
  }

  pathnameHasChanged(prevProps) {
    return this.props.router.location.pathname !== prevProps.router.location.pathname;
  }

  componentDidUpdate(prevProps) {
    const { drawer, drawerActions } = this.props;

    if (drawer.get('open') && this.pathnameHasChanged(prevProps)) {
      drawerActions.toggle();
    }
  }

  render() {
    const {
      account,
      isRecentlyReturnedEnabled,
      isAccountMessagesEnabled,
      isRemainingHoldsEnabled,
      isIllRequestsEnabled,
      showCardExpiryDate
    } = this.props;
    const expiryDate = account.get('expiryDate');

    return (
      <div className="cp-my-borrowing-sidebar">
        <div className="panel panel-default">
          <div className="close-drawer">
            <Drawer.Toggle drawerActions={this.props.drawerActions}>
              <TextButton className="panel-close" handleClick={this.props.drawerActions.toggle}>
                <ChevronLeft className="panel-close-icon" />
                Close
              </TextButton>
            </Drawer.Toggle>
          </div>

          <div className="panel-heading">{this.renderTitle()}</div>
          <div className="panel-body">
            <div className="list-group">
              {showCardExpiryDate && <AccountExpiryWarning expiryDate={expiryDate} />}
              <HoldsStatus
                summary={this.props.holdsSummary}
                currentSection={this.isActive('holds')}
                ILSQuotas={this.props.ILSQuotas}
                isRemainingHoldsEnabled={isRemainingHoldsEnabled}
              />
              <CheckedOutStatus summary={this.props.checkoutSummary} currentSection={this.isActive('checkedout')} />
              {isRecentlyReturnedEnabled && (
                <RecentlyReturnedStatus
                  totalBorrowingHistory={this.props.totalBorrowingHistory}
                  currentSection={this.props.activeSection === 'recentlyreturned'}
                />
              )}
              <FinesStatus
                totalFines={this.props.totalFines}
                currentSection={this.props.activeSection === 'fines'}
                accruingFines={this.props.accruingFines}
              />
              {isIllRequestsEnabled && (
                <InterlibraryLoansStatus
                  summary={this.props.illSummary}
                  currentSection={this.props.activeSection === 'interlibraryloans'}
                />
              )}
              {isAccountMessagesEnabled && (
                <AccountMessagesStatus
                  messages={this.props.messages}
                  currentSection={this.props.activeSection === 'accountmessages'}
                />
              )}
            </div>
          </div>
          {this.renderSingleClickHoldsWidget()}
          {this.showLinkableLibraries() && (
            <LinkableLibrariesPanel
              accountsByLibraryId={this.props.accountsByLibraryId}
              linkableLibraries={this.props.linkableLibraries}
            />
          )}
        </div>
      </div>
    );
  }
}

MyBorrowingSidebar.propTypes = {
  account: accountShape,
  activeSection: PropTypes.string.isRequired,
  libraryName: PropTypes.string,
  linkableLibraries: ImmutablePropTypes.listOf(siteShape),
  checkoutSummary: ImmutablePropTypes.mapContains({
    nextDue: ImmutablePropTypes.mapContains({
      date: PropTypes.string,
      status: PropTypes.string
    })
  }),
  totalFines: PropTypes.number.isRequired,
  totalBorrowingHistory: PropTypes.number.isRequired,
  ILSQuotas: ImmutablePropTypes.map,
  messages: ImmutablePropTypes.map.isRequired,
  status: ImmutablePropTypes.mapOf(PropTypes.number, PropTypes.string),
  accountsByLibraryId: ImmutablePropTypes.mapOf(accountShape),
  drawerActions: PropTypes.objectOf(PropTypes.func),
  isRecentlyReturnedEnabled: PropTypes.bool,
  isAccountMessagesEnabled: PropTypes.bool,
  isRemainingHoldsEnabled: PropTypes.bool,
  isIllRequestsEnabled: PropTypes.bool,
  illSummary: ImmutablePropTypes.map,
  showCardExpiryDate: PropTypes.bool,
  router: routerShape.isRequired,
  drawer: ImmutablePropTypes.mapContains({
    open: PropTypes.bool
  })
};

export default withRouter(MyBorrowingSidebar);
