import React from 'react';
import PropTypes from 'prop-types';
import { DocumentHeadContainer } from 'app/components/shared/DocumentHead';
import Forbidden from './Forbidden';

export default function ForbiddenPage(props) {
  return (
    <div className="container">
      <DocumentHeadContainer pageTitle="403" />
      <Forbidden {...props} />
    </div>
  );
}

ForbiddenPage.propTypes = {
  pageTitle: PropTypes.string,
  exploreLink: PropTypes.string,
  exploreTextId: PropTypes.string
};
