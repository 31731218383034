import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import { Link } from '@bibliocommons/base-links';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';

import './Forbidden.scss';

export default function Forbidden({ pageTitle, exploreLink, exploreTextId }) {
  const intl = useIntl();
  const currentLibrary = useSelector(selectCurrentLibrary);
  const href = exploreLink || '/';
  const messageId = exploreTextId || 'go_to_home_page';

  return (
    <div className="cp-forbidden">
      <h1>
        <FormattedHTMLMessage
          defaultMessage={intl.formatMessage({ id: 'errors.forbidden.title_default' })}
          id={`errors.forbidden.title_${pageTitle}`}
        />
      </h1>
      <p>
        <FormattedHTMLMessage
          id={`errors.forbidden.description_${pageTitle}`}
          defaultMessage={intl.formatMessage({ id: 'errors.forbidden.description_default' })}
          values={{ libraryName: currentLibrary.get('longName') }}
        />
      </p>
      <Link href={href} dataKey="explore_link">
        <FormattedHTMLMessage id={messageId} />
      </Link>
    </div>
  );
}

Forbidden.propTypes = {
  pageTitle: PropTypes.string,
  exploreLink: PropTypes.string,
  exploreTextId: PropTypes.string
};
